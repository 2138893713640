import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";
import LoadingScreen from "../../components/LoadingScreen";
import ErrorContent from "../../components/ErrorContent";
import LoadingButton from "../../components/LoadingButton";
import { academicYear, questionOption, questionOptionKey, status } from "../../api/types";
import { ExamType, PastExamQuestion } from "../../grpc/dash/v1/models_pb";
import { pastExamServiceGetPastExam, pastExamServiceTrimPastExam } from "../../api/past_exam";
import Marker from "../../components/PastExam/Marker";

function ExamTrim() {
    let { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [data, setData] = useState(new ExamType().toObject());
    const [fileInfo, setFileInfo] = useState({});

    const [previewIndex, setPreviewIndex] = useState();
    const [previewMark, setPreviewMark] = useState({});
    const [quickAnswers, setQuickAnswers] = useState("");

    const loadScreen = async () => {
        try {
            const result = await pastExamServiceGetPastExam(id);
            const object = result.toObject();
            setData(object.pastExam);
            setFileInfo(object.fileInfo);
            if (object.pastExam.questionsList.length === 0)
                setPreviewMark({ page: object.fileInfo.pagesList.length });
            setQuickAnswers(object.pastExam.questionsList.map((item, index) => { return questionOption(item.correct); }).join(""));
            setLoading(false);
        } catch (err) {
            setError(err);
            setLoading(false);
        }
    }
    const onPressContentMarkEdit = (index, item) => {
        onPressMarkEdit("content-" + index, item.contentMark);
    }
    const onPressHintMarkEdit = (index, item) => {
        onPressMarkEdit("hint-" + index, item.hintMark);
    }
    const onPressInfoMarkEdit = (index, item) => {
        onPressMarkEdit("info-" + index, item.infoMark);
    }
    const onPressMarkEdit = async (index, mark) => {
        setPreviewIndex(index);
        setPreviewMark(mark);
    }
    const onPressUpdateOption = (index, option) => {
        data.questionsList[index].correct = option;
        setData({ ...data });
        setQuickAnswers(
            data.questionsList.map((item, index) => {
                return questionOption(item.correct);
            }).join("")
        )
    }
    const onChangeMarkHandcrafted = (index, mark) => {
        data.questionsList[index][`${mark}Mark`].handcrafted = !data.questionsList[index][`${mark}Mark`].handcrafted;
        setData({ ...data });
    }
    const QuestionPrevious = () => {
        let index = previewIndex === undefined ? 0 : parseInt(previewIndex.split("-")[1]);
        let previousIndex = index - 1
        if (previousIndex < 0)
            return;
        onPressMarkEdit("content-" + previousIndex, data.questionsList[previousIndex].contentMark);
    }
    const QuestionNext = () => {
        let index = previewIndex === undefined ? -1 : parseInt(previewIndex.split("-")[1]);
        let nextPreviewIndex = index + 1;
        if (nextPreviewIndex >= data.questionsList.length)
            return;
        onPressMarkEdit("content-" + nextPreviewIndex, data.questionsList[nextPreviewIndex].contentMark);
    }
    const handleKeydown = (event) => {
        switch (event.key) {
            case "ArrowLeft":
            case "z":
                QuestionPrevious();
                break;
            case "ArrowRight":
            case "x":
                QuestionNext();
                break;
        }
    };
    const pageInfo = () => {
        if (data.questionsList == 0) {
            return <div className="alert alert-warning" role="alert">
                <b>Cevap Anahtarını doldur.!</b>
            </div>
        }
        if (previewIndex == undefined)
            return <></>
        let index = previewIndex === undefined ? 0 : parseInt(previewIndex.split("-")[1]);
        let tag = previewIndex === undefined ? 'hint' : previewIndex.split("-")[0];
        if (tag == "answer_key")
            return <div className="alert alert-warning" role="alert">Cevap Anahtarını seçin.</div>

        return <div className="alert" role="alert" style={{ backgroundColor: "#ff000020", border: "1px solid #00000040" }}>
            <b>{index + 1}. {{ "hint": "SORU İPUCU", "info": "SORU BİLGİ" }[tag] ?? "SORU"}</b>
        </div>
    }
    const onPressSave = async () => {
        setLoading(true);
        setPreviewIndex();
        setPreviewMark({});
        const response = await pastExamServiceTrimPastExam(data.id, data.questionsList);
        data.questionsList = response.toObject().questionsList;
        setData({ ...data });
        setLoading(false);
        navigate(`/exams`);
    }

    const RenderContent = () => {
        if (loading)
            return <LoadingScreen />;
        if (error !== null)
            return <ErrorContent error={error} />
        return <>
            <div className="row justify-content-md-center sticky-top" style={{ zIndex: 0 }}>
                <div className="col-4"></div>
                <div className="col-md-auto p-1">
                    {pageInfo()}
                </div>
            </div>
            <div className="row">
                <div className="col-4">
                    <table className="card-body table mb-0">
                        <tbody>
                            <tr>
                                <th scope="col">Alan:</th>
                                <td>{data.area?.title ?? "-"}</td>
                            </tr>
                            <tr>
                                <th scope="col">Grup:</th>
                                <td>{data.examGroup?.title ?? "-"}</td>
                            </tr>
                            <tr>
                                <th scope="col">Tip:</th>
                                <td>{data.examType?.title ?? "-"}</td>
                            </tr>
                            <tr>
                                <th scope="col">Ders:</th>
                                <td>{data.lesson?.title ?? "-"} ({data.lesson?.code ?? "-"})</td>
                            </tr>
                            <tr>
                                <th scope="col">Akademik Yıl:</th>
                                <td>{academicYear(data.academicYear ?? 0)}</td>
                            </tr>
                            <tr>
                                <th scope="col">Tarih:</th>
                                <td>{new Date(data.date.seconds * 1000).toISOString().slice(0, -8).replace("T", " ")}</td>
                            </tr>
                        </tbody>

                    </table>
                    <table className="card-body table mb-0">
                        <thead>
                            <tr>
                                <th scope="col" className="text-center" colSpan={3}>CEVAP ANAHTARI</th>
                            </tr>
                            <tr>
                                <th scope="col" className="text-end">#</th>
                                <th scope="col">
                                    <span className="badge text-bg-danger mx-1">Soru</span>
                                    <span className="badge text-bg-success mx-1">İpucu</span>
                                    <span className="badge text-bg-info">Bilgi</span>
                                </th>
                                <th scope="col">CEVAP</th>
                            </tr>
                        </thead>
                        <tbody>
                            {

                                data.questionsList.map((item, index) => {
                                    if (item.hintMark == null)
                                        item.hintMark = new PastExamQuestion.Mark().toObject();
                                    if (item.contentMark == null)
                                        item.contentMark = new PastExamQuestion.Mark().toObject();
                                    if (item.infoMark == null)
                                        item.infoMark = new PastExamQuestion.Mark().toObject();
                                    return (
                                        <tr key={"question_" + index}>
                                            <th scope="row" className="col-sm-1 text-end">
                                                {item.questionNumber}
                                            </th>
                                            <td className="px-0">
                                                <div className="input-group">
                                                    <button type="button" title={"Sayfa:" + item.contentMark.page + "(" + item.contentMark.width + "x" + item.contentMark.height + ")"} className={`btn btn-sm btn-outline-danger ${"content-" + index == previewIndex ? " active" : ""}`} role="button" onClick={() => onPressContentMarkEdit(index, item)}>
                                                        {
                                                            item.contentMark.width == 0 || item.contentMark.height == 0 ? "-" : "+"
                                                        }
                                                    </button>
                                                    <div className="input-group-text p-2">
                                                        <input className="form-check-input mt-0" type="checkbox" checked={item.contentMark.handcrafted} onChange={() => {
                                                            onChangeMarkHandcrafted(index, "content");
                                                        }} />
                                                    </div>
                                                    <button type="button" title={"Sayfa:" + item.hintMark.page + "(" + item.hintMark.width + "x" + item.hintMark.height + ")"} className={`btn btn-sm btn-outline-success ${"hint-" + index == previewIndex ? " active" : ""}`} role="button" onClick={() => onPressHintMarkEdit(index, item)}>
                                                        {
                                                            item.hintMark.width == 0 || item.hintMark.height == 0 ? "-" : "+"
                                                        }
                                                    </button>
                                                    <div className="input-group-text p-2">
                                                        <input className="form-check-input mt-0" type="checkbox" checked={item.hintMark.handcrafted} onChange={() => {
                                                            onChangeMarkHandcrafted(index, "hint");
                                                        }} />
                                                    </div>

                                                    <button type="button" title={"Sayfa:" + item.infoMark.page + "(" + item.infoMark.width + "x" + item.infoMark.height + ")"} className={`btn btn-sm btn-outline-info ${"info-" + index == previewIndex ? " active" : ""}`} role="button" onClick={() => onPressInfoMarkEdit(index, item)}>
                                                        {
                                                            item.infoMark.width == 0 || item.infoMark.height == 0 ? "-" : "+"
                                                        }
                                                    </button>
                                                    <div className="input-group-text p-2">
                                                        <input className="form-check-input mt-0" type="checkbox" checked={item.infoMark.handcrafted} onChange={() => {
                                                            onChangeMarkHandcrafted(index, "info");
                                                        }} />
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="px-0">
                                                <div className="btn-group btn-group-sm" role="group">
                                                    {
                                                        [
                                                            questionOption().QUESTION_OPTION_A,
                                                            questionOption().QUESTION_OPTION_B,
                                                            questionOption().QUESTION_OPTION_C,
                                                            questionOption().QUESTION_OPTION_D,
                                                            questionOption().QUESTION_OPTION_E,
                                                            questionOption().QUESTION_OPTION_CANCELED,
                                                        ].map((option) => {
                                                            return <button key={"option-input-" + index + option} className={`btn btn-sm btn-outline-success ${item.correct == option ? " active" : ""}`} role="button" onClick={() => onPressUpdateOption(index, option)}>{questionOption(option)}</button>
                                                        })
                                                    }
                                                </div>


                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <Marker
                    col={6}
                    width={Math.ceil(fileInfo.width * (150 / fileInfo.resolution))}
                    height={Math.ceil(fileInfo.height * (150 / fileInfo.resolution))}
                    resolution={Math.ceil(fileInfo.resolution * (150 / fileInfo.resolution))}
                    pages={fileInfo.pagesList}
                    mark={previewMark}
                    markIndex={previewIndex}
                    setMark={(key, mark) => {
                        if (key === undefined)
                            return
                        const m = key.split("-");
                        const tag = m[0];
                        const questionIndex = parseInt(m[1]);
                        switch (tag) {
                            case "content":
                                data.questionsList[questionIndex].contentMark = mark;
                                break;
                            case "hint":
                                data.questionsList[questionIndex].hintMark = mark;
                                break;
                            case "info":
                                data.questionsList[questionIndex].infoMark = mark;
                                break;
                            default:
                                return
                        }
                        setPreviewMark(mark);
                        setData({ ...data })
                    }}
                />


            </div>
            <div className="row sticky-bottom bg-white pb-1">
                <div className="col-12">
                    <div className="row justify-content-center">
                        <div className="col-3">
                            <div className="alert alert-light" role="alert">
                                <div className="btn-group" role="group">
                                    <button type="button" title="Değişiklikleri kaydeder." className={`btn btn-sm btn-outline-primary`} role="button" onClick={() => { onPressSave(); }}>
                                        KIRP VE ÖNİZLE
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-9 ">
                            <div className="alert alert-light" role="alert">
                                <div className="form-group">
                                    <input autoComplete="off" type="text" className="form-control form-control-sm" value={quickAnswers} placeholder="CEVAP ANAHTARI" onChange={(event) => {
                                        event.target.value = event.target.value.replace(/[^\(A|B|C|D|E|İ)\s]/gi, "");
                                        setQuickAnswers(event.target.value.toUpperCase());
                                    }} id="quick-answers" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    };


    useEffect(() => {
        quickAnswers.split("").forEach((item, index) => {
            if (data.questionsList[index] !== undefined) {
                data.questionsList[index].correct = questionOptionKey(item);
            } else {
                data.questionsList.push({
                    examId: id,
                    questionNumber: data.questionsList.length + 1,
                    correct: questionOptionKey(item),
                    contentMark: new PastExamQuestion.Mark().toObject(),
                    hintMark: new PastExamQuestion.Mark().toObject(),
                    infoMark: new PastExamQuestion.Mark().toObject()
                })
            }
        });
        setData({ ...data })
    }, [quickAnswers]);

    useEffect(() => {
        window.addEventListener("keydown", handleKeydown);
        return () => window.removeEventListener("keydown", handleKeydown);
    });

    useEffect(() => {
        loadScreen();
    }, []);
    return <div className="container-fluid">
        {RenderContent()}
    </div>;
}

export default ExamTrim;